import Tiles from './tiles/Tiles';

export const WellnessBanner = ({ theme, showTiles = true }) => {
  const WELLNESS_BANNER_ALT_TEXT = 'wellness collection banner';

  return (
    <>
      <div className="bg-water" />

      {showTiles && <Tiles />}

      <div className="wellness-banner">
        <img alt={WELLNESS_BANNER_ALT_TEXT} src={theme.images.banner} />
      </div>

      <style jsx>{`
        .bg-water {
          z-index: -1;
          width: 100%;
          height: 100%;
          position: fixed;
          background-size: cover;
          background-image: url(https://static.wowcher.co.uk/binaries/wellness_colourwash.jpg);
          background-position: center center;
          top: 0px;
          left: 0px;
        }

        .wellness-banner {
          margin-bottom: 20px;
          width: 100%;
        }

        .wellness-banner img {
          width: 100%;
        }
      `}</style>
    </>
  );
};
