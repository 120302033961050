import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useBreadcrumbs } from '../../helpers/breadcrumbs';
import ThemeContext from '../../providers/ThemeProvider';

const BreadCrumb = ({ deal, className }) => {
  const theme = useContext(ThemeContext);
  const breadcrumbs = useBreadcrumbs(deal);
  const getLastElement = (index, max, item) => {
    if (index + 1 >= max) return <span itemProp="name">{item.linkText}</span>;

    return (
      <Link href={item.url ?? ''}>
        <a itemProp="item">
          <span itemProp="name">{item.linkText}</span>
        </a>
      </Link>
    );
  };

  if (!breadcrumbs || breadcrumbs.length <= 1) return null;

  return (
    <>
      <ul
        className={classNames('breadcrumb-container', className)}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {breadcrumbs.map((item, index) => (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            key={`breadcrumb_item__${index}`}
          >
            {getLastElement(index, breadcrumbs.length, item)}
            <meta content={`${index + 1}`} itemProp="position" />
          </li>
        ))}
      </ul>

      <style jsx>{`
        .breadcrumb-container {
          list-style: none;
          margin-left: 0px;
          margin-block-start: 0em;
          margin-block-end: 0px;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 0px;
        }
        .breadcrumb-container > li {
          display: inline;
          color: #000;
        }
        .breadcrumb-container > li:after {
          font-family: 'Open Sans';
          content: '>';
          color: #000;
          position: relative;
          font-size: 1rem;
          margin: 0 8px 0 8px;
          top: -1px;
        }
        .breadcrumb-container > li:last-child:after {
          content: none;
        }
        @media only screen and (min-width: ${theme.breakpoints.mdUp}) {
          .breadcrumb-container > li:after {
            color: ${theme.colors.breadcrumb};
          }
        }
      `}</style>
      <style global jsx>{`
        .breadcrumb-container > li > a {
          cursor: pointer;
          text-transform: capitalize;
          text-decoration: none;
        }
        .breadcrumb-container > li:last-child > span {
          color: #000;
        }
        .breadcrumb-container > li > a:hover {
          color: ${theme.colors.primary};
          text-decoration: underline;
        }

        @media only screen and (min-width: ${theme.breakpoints.mdUp}) {
          .breadcrumb-container > li > a {
            cursor: pointer;
            color: ${theme.colors.breadcrumbpale};
            text-transform: capitalize;
            text-decoration: none;
          }
          .breadcrumb-container > li:last-child > span {
            color: ${theme.colors.breadcrumb};
          }
          .breadcrumb-container > li > a:hover {
            color: ${theme.colors.breadcrumbhover};
            text-decoration: underline;
          }
          .breadcrumb-container {
            text-shadow: ${theme.styles.breadcrumbtextshadow};
          }
        }
      `}</style>
    </>
  );
};

export default BreadCrumb;

BreadCrumb.propTypes = {
  className: PropTypes.string,
  deal: PropTypes.object,
};

BreadCrumb.defaultProps = {
  className: '',
  deal: null,
};
