/* eslint-disable complexity */
import classNames from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import ReactCookies from 'react-cookies';
import { useSelector } from 'react-redux';
import { PAGE_TYPES } from '../../config/constants/page-types';
import COOKIES from '../../config/cookies/cookies';
import {
  SITE_LIVINGSOCIAL_IE,
  DEALS_PROMO_TYPES,
} from '../../config/setup/setup';
import { trackEvent, updatePersistentProperty } from '../../helpers/analytics';
import { parseFacetedNavigation } from '../../helpers/facetedNavigation';
import { getSeoText } from '../../helpers/getSeoText';
import { useHeight } from '../../helpers/getSeoTextHeight';
import { getMapCategories } from '../../helpers/mapview';
import { isDesktop, useScreenBreakpoint } from '../../helpers/screen';
import {
  parseWowcherPath,
  pathLocationInfo,
  getIsShopLayout,
} from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import { FEATURED_DEALS_LOADED } from '../../redux/reducers/deals';
import Expander, { TYPE_ABSOLUTE } from '../_generic/expander/Expander';
import CarouselDealBlock from '../deal/shared-components/CarouselDealBlock';
import DynamicMapWidget from '../map-view/DynamicMapWidget';
import FacetedNavigation from './FacetedNavigation';
import SeoText from './SeoText';

// eslint-disable-next-line sonarjs/cognitive-complexity
const FacetedNavigationContainer = ({ isDealPage, earlyBirdData }) => {
  const theme = useContext(ThemeContext);
  const site = process.env.NEXT_PUBLIC_SITE;
  const livingsocialie = site === SITE_LIVINGSOCIAL_IE;
  const [
    facetedNavigation,
    location,
    locations,
    navigation,
    recommendedDeals,
    newProductDeals,
    recentlyViewedDeals,
    featuredDealsLoaded,
  ] = useSelector((state) => [
    state.deals.facetedNavigation,
    state.locations.location,
    state.locations.locations,
    state.navigation.list || [],
    state.deals.recommendedDeals,
    state.deals.newProductDeals,
    state.deals.recentlyViewedDeals,
    state.deals.featuredDealsLoaded,
  ]);

  const MapViewEnabled = ReactCookies.load(COOKIES.mapViewTest) === 'b';
  const router = useRouter();
  const path = router.asPath;
  const { pageType, details } = parseWowcherPath(path);

  const {
    foundLocationName,
    foundLocationShortName,
    hasLocation,
    isLocationPage,
  } = pathLocationInfo(path, locations);
  const isShopLayout = getIsShopLayout(pageType);
  const isCategoryPage = Boolean(pageType === PAGE_TYPES.category);
  const isHyperLocal = Boolean(details.isHyperlocal);
  const isWellnessPage = details.localDealLocation === PAGE_TYPES.wellness;
  const mapWidgetCategories = !details?.subCategory
    ? getMapCategories(details?.category)
    : '';
  const isMapCategoryPage = isCategoryPage && mapWidgetCategories.length > 0;
  const isShowMapWidget =
    (isLocationPage || isMapCategoryPage) && MapViewEnabled;
  const showCarousel =
    (isWellnessPage && !isDealPage) || (isLocationPage && !isHyperLocal);

  const parsed = parseFacetedNavigation({
    facetedNavigation,
    location: location.shortName,
    navigation,
    path,
  });
  const resultNavigations = parsed?.navigations || [];
  const resultLinkText = parsed?.defaultItem?.linkText || '';
  const resultLinkTextCount = parsed?.defaultItem?.count
    ? `(${parsed.defaultItem.count})`
    : '';
  const resultToggleTitle = `${resultLinkText} ${resultLinkTextCount}`;

  const { seoTitle, seoText } = getSeoText(
    facetedNavigation,
    foundLocationName,
    foundLocationShortName,
    hasLocation,
    isHyperLocal,
    isLocationPage,
    isWellnessPage,
    locations,
    parsed,
    router,
    theme,
  );

  const breakpoint = useScreenBreakpoint(true);

  const height = useHeight(
    breakpoint,
    isShopLayout,
    isLocationPage,
    MapViewEnabled,
    isMapCategoryPage,
  );

  const sliceDeals = (deals, updatedPromoType) =>
    deals.slice(0, 4).map((deal) => {
      deal.promoType = updatedPromoType;

      return deal;
    });

  const recentDeals = sliceDeals(
    recentlyViewedDeals,
    DEALS_PROMO_TYPES().recentlyViewed,
  );

  const earlyBirdDeals = sliceDeals(
    earlyBirdData,
    DEALS_PROMO_TYPES().earlyBird,
  );

  const recommendDeals = sliceDeals(
    recommendedDeals,
    DEALS_PROMO_TYPES().recommended,
  );

  const newDeals = sliceDeals(
    newProductDeals,
    DEALS_PROMO_TYPES(`/deal/${location.shortName}/special/new-products`)
      .newProducts,
  );

  useEffect(() => {
    if (featuredDealsLoaded === FEATURED_DEALS_LOADED.COMPLETE) {
      const trackedData = [...recentDeals, ...newDeals, ...recommendDeals];
      updatePersistentProperty(
        'promoCarouselDeals',
        trackedData.map((deal) => {
          return { id: deal.id, promoType: deal.promoType.title };
        }),
      );
      trackEvent('promo_carousel');
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [featuredDealsLoaded]);

  useEffect(() => {
    const propertyUpdateOnUnload = () => {
      updatePersistentProperty('promoCarouselDeals');
    };

    window.addEventListener('beforeunload', propertyUpdateOnUnload);

    return () => {
      window.removeEventListener('beforeunload', propertyUpdateOnUnload);
    };
  }, []);

  let { carouselOne, carouselTwo } = useMemo(() => {
    if (
      isWellnessPage ||
      isLocationPage ||
      (isShopLayout && breakpoint === 'xs')
    ) {
      return {
        carouselOne: livingsocialie
          ? [...recentDeals, ...newDeals]
          : [...recommendDeals, ...recentDeals, ...newDeals],
        carouselTwo: [],
      };
    }

    if (isShopLayout && !livingsocialie) {
      return {
        carouselOne: recommendDeals,
        carouselTwo: [...recentDeals, ...newDeals],
      };
    }
    if (isShopLayout && livingsocialie) {
      return {
        carouselOne: recentDeals,
        carouselTwo: newDeals,
      };
    }

    return {
      carouselOne: [],
      carouselTwo: [],
    };
  }, [
    livingsocialie,
    recommendDeals,
    recentDeals,
    newDeals,
    breakpoint,
    isShopLayout,
    isLocationPage,
    isWellnessPage,
  ]);

  if (earlyBirdData.length > 0) carouselTwo = earlyBirdDeals;
  const isWellnessHeight =
    isWellnessPage && (breakpoint === 'md' || breakpoint === 'sm');

  return (
    <>
      {!isShopLayout && (
        <div
          className={classNames('seo-nav-container', {
            'seo-nav-container--mobile-only': isDealPage,
          })}
        >
          <div
            className={classNames('promo-block', {
              'subcat-block':
                !isShopLayout && !isLocationPage && !isWellnessPage,
            })}
          >
            {!isDealPage && (
              <Expander
                bgcolor={theme.colors.navbackground}
                breakpoint={breakpoint}
                initHeight={isWellnessHeight ? '100%' : height}
                type={TYPE_ABSOLUTE}
              >
                <SeoText text={seoText} title={seoTitle} />
              </Expander>
            )}
            {isShowMapWidget ? (
              <DynamicMapWidget
                categoryQuery={mapWidgetCategories}
                displayWidgetButton={breakpoint === 'xl'}
              />
            ) : null}
            {(isDealPage || isCategoryPage) && (
              <FacetedNavigation
                navigations={resultNavigations}
                toggleText={resultToggleTitle}
              />
            )}
            {showCarousel && isDesktop() && (
              <CarouselDealBlock deals={carouselOne} />
            )}
          </div>
        </div>
      )}
      {isShopLayout && (
        <div className="promo-block">
          {earlyBirdData.length > 0 ? (
            <>
              <CarouselDealBlock deals={carouselTwo} earlyBird />
              <CarouselDealBlock deals={carouselOne} />
            </>
          ) : (
            <>
              <CarouselDealBlock deals={carouselOne} />
              <CarouselDealBlock deals={carouselTwo} />
            </>
          )}
        </div>
      )}
      <style jsx>{`
        .seo-nav-container {
          padding-top: 1px;
          position: relative;
          width: 100%;
          min-height: 450px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .promo-block {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .seo-nav-container--mobile-only {
          display: none;
          padding: 10px;
        }
        .promo-block {
          flex-direction: column;
        }
        .promo-block :global(.carousel-deal:first-child) {
          margin-bottom: 20px;
        }
        :global(.seo-nav-container--mobile-only .faceted-navigation__list) {
          background-color: white !important;
          border: 1px solid #aaaaaa;
        }
        @media (max-width: ${theme.breakpoints.xlDown}) {
          .promo-block {
            flex-direction: row;
            align-items: flex-start;
          }
          .promo-block :global(.carousel-deal) {
            width: calc(50% - 10px);
          }
          .promo-block :global(.carousel-deal:first-child) {
            margin-bottom: 0;
          }
          .promo-block :global(.expander-container),
          .promo-block :global(.faceted-navigation__container) {
            width: calc(50% - 10px);
          }
          .promo-block :global(.expander-closed) {
            margin-bottom: 0;
          }
          .seo-nav-container {
            min-height: 0;
          }
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .seo-nav-container {
            background-color: transparent;
          }
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .subcat-block {
            display: block;
          }
          .subcat-block :global(.expander-container) {
            width: 100%;
          }
          .subcat-block :global(.faceted-navigation__container) {
            width: 100%;
          }
          .seo-nav-container :global(.expander-container .absolute) {
            position: relative;
          }
          .seo-nav-container :global(.expander-container .spacer) {
            height: auto;
          }
        }
        @media (max-width: ${theme.breakpoints.smDown}) {
          .promo-block {
            display: block;
          }
          .seo-nav-container {
            justify-content: unset;
            height: 100%;
            min-height: inherit;
          }
          .seo-nav-container--mobile-only {
            display: block;
            height: auto;
          }
          .promo-block :global(.carousel-deal) {
            width: 100%;
          }
          .promo-block :global(.expander-container) {
            width: 100%;
          }
          .promo-block :global(.faceted-navigation__container) {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

FacetedNavigationContainer.propTypes = {
  earlyBirdData: PropTypes.array,
  isDealPage: PropTypes.bool,
};
FacetedNavigationContainer.defaultProps = {
  earlyBirdData: [],
  isDealPage: false,
};

export default FacetedNavigationContainer;
